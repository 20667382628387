import React, { useContext, useEffect } from "react"
import Router from "next/router"
import { Layout as AntLayout, Spin } from "antd"
import styled from "styled-components"
import { AuthContext } from "./utils/AuthContext"
import { Layout } from "./layout"
import { Sidebar } from "./components/molecules/Siderbar"
import { Header } from "./components/molecules/Header"
import { Footer } from "./components/molecules/Footer"
import Link from "next/link"
import { t } from "i18next"
import { UnderMaintenanceCard } from "@project/shared"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const { Content } = AntLayout

const StyledMaintenanceCard = styled(UnderMaintenanceCard)`
  margin: 40px;
  @media (max-width: 767px) {
    margin: 20px;
  }
`

const PrivateRoute = (AuthComponent, additionalProps: any = {}) => {
  const { title = "Operation Dashboard", activeMenu = "" } = additionalProps

  function PrivateComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext)
    useEffect(() => {
      const { pathname } = Router
      if (!loading) {
        if (!authenticated) {
          Router.push("/login")
          return
        }
        if (authenticated && pathname === "/") {
          Router.push("/company")
        }
      }
    }, [authenticated, loading, user])

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size={"large"} className={"loader"} />
        </LoaderWrapper>
      )
    }
    return <>{authenticated && <>{children}</>}</>
  }

  type HigherState = { collapsed: boolean }
  return class Higher extends React.Component<
    Record<string, unknown>,
    HigherState
  > {
    constructor(props) {
      super(props)
      this.state = {
        collapsed:
          typeof window !== "undefined"
            ? localStorage.getItem("collapse_menu") === "true"
            : false,
      }
    }

    handleCollapse = () => {
      localStorage.setItem(
        "collapse_menu",
        this.state.collapsed ? "false" : "true"
      )
      this.setState((state) => ({
        collapsed: !state.collapsed,
      }))
    }

    handleSetCollapsed = () => {
      this.setState((state) => ({
        ...state,
        collapsed: true,
      }))
    }

    render() {
      return (
        <PrivateComponent>
          <Layout className={"dashboard"}>
            <Sidebar
              collapsed={this.state.collapsed}
              handleCollapse={this.handleCollapse}
              handleSetCollapsed={this.handleSetCollapsed}
              activeKey={activeMenu}
            />
            <AntLayout className={"site-layout dashboard-site-content"}>
              <div className={"dashboard-mobile-sidebar-header"}>
                <Link href={"/"}>
                  <a>
                    <div
                      className={"dashboard-mobile-siderbar-header-logo-title"}
                    >
                      <div className={"sidebar-header-logo"}>
                        <img src={"assets/icons/logo.svg"} />
                      </div>
                      &nbsp;&nbsp;
                      {t("Growth Rehabilitation Support System")}
                    </div>
                  </a>
                </Link>
                <div>
                  {this.state.collapsed ? (
                    <img
                      src={"assets/icons/MobileMenuTogglerIcon.svg"}
                      onClick={this.handleCollapse}
                    />
                  ) : (
                    <img
                      src={"assets/icons/CloseIcon.svg"}
                      onClick={this.handleCollapse}
                    />
                  )}
                </div>
              </div>
              <Header title={title} />
              <Content className={"dashboard-content"}>
                {process.env.NEXT_PUBLIC_MAINTENANCE_MODE_OPERATION === "1" ? (
                  <StyledMaintenanceCard />
                ) : (
                  <div className={"site-layout-background"}>
                    <AuthComponent {...this.props} />
                  </div>
                )}
              </Content>
              <Footer />
            </AntLayout>
          </Layout>
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
