import { Menu, Layout } from "antd"
import FacilityOperationIcon from "../../../public/assets/icons/FacilityOperationIcon.svg"
import HomeIcon from "../../../public/assets/icons/HomeIcon.svg"
import CollapseIcon from "../../../public/assets/icons/CollapseIcon.svg"
import EASYYLOGO from "../../../public/assets/icons/logo.svg"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import Link from "next/link"
import { auth } from "@project/shared"
import { signOut } from "firebase/auth"
interface Props {
  collapsed: boolean
  handleCollapse: () => void
  activeKey?: string
  handleSetCollapsed: () => void
}

const { Sider } = Layout

const sideBarItems = [
  {
    key: "company",
    icon: React.createElement(HomeIcon),
    label: "Company list",
    link: "/company",
  },
  {
    key: "notification",
    icon: React.createElement(FacilityOperationIcon),
    label: "Notification Management",
    link: "/notification",
  },
]

interface SiderStyleProps {
  collapsed: boolean
}

const SiderStyled = styled(Sider)<SiderStyleProps>`
  z-index: 9;
  &.operation-sidebar {
    min-height: 100%;
    border-right: 0;
    background: #fdd95f;
  }
  &.operation-sidebar ul {
    background: #fdd95f;
  }
  &.operation-sidebar .ant-menu-submenu-open,
  &.operation-sidebar .ant-menu-submenu-open ul {
    background: #feecaf;
  }
  .operation-sidebar-trigger {
    position: absolute;
    left: ${(props) => (props.collapsed ? "80px" : "250px")};
    top: 44px;
    width: 50px !important;
    background: #ffffff;
    color: #000000;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
  }
  .operation-sidebar-trigger-rotate {
    transform: rotate(180deg);
  }
  .operation-siderbar-header-container {
    background: #444748;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }
  .operation-sidebar-header-logo {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
  }
  .operation-sidebar-header-title {
    margin-left: 5px;
  }
  .operation-sidebar-menu .ant-menu-submenu-title {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .operation-sidebar-menu-divider {
    margin-left: 16px !important;
    margin-right: 16px !important;
    border-color: #5c5f60;
  }
  .ant-menu-item {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .operation-dashboard-sidebar-logout .ant-menu-item {
    padding-left: ${(props) => (props.collapsed ? "30px" : "54px")};
    padding-top: 0px;
  }
  .ant-menu-item-selected {
    background-color: #feecaf !important;
    color: #000000 !important;
  }
  @media (max-width: 768px) {
    &.operation-sidebar {
      position: fixed;
      display: ${(props) => (props.collapsed ? "none" : "")};
    }
    .operation-sidebar-trigger {
      display: none;
    }
    .operation-siderbar-header-container {
      display: none;
    }
    .operation-dashboard-sidebar-logout .ant-menu-item {
      margin-left: 0px;
    }
  }
  @media print {
    display: none;
  }
`
const LogoutWrapper = styled.span<SiderStyleProps>`
  margin-left: ${(props) => (props.collapsed ? "0" : "-30px !important")};
  > img {
    height: 20px;
  }
`

const Sidebar = (props: Props) => {
  const { t } = useTranslation()
  const {
    collapsed,
    handleCollapse,
    handleSetCollapsed,
    activeKey = "",
  } = props
  const [openKeys, setOpenKeys] = useState([])
  const [, setUser] = useState(null)
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    setOpenKeys([latestOpenKey])
  }
  const [width, setWidth] = useState(window.innerWidth)
  const { push } = useRouter()

  const logOut = () => {
    signOut(auth)
    setUser(null)
    window.location.href = "/login"
  }
  addEventListener("resize", () => {
    setWidth(window.innerWidth)
  })
  useEffect(() => {
    if (width <= 768) {
      handleSetCollapsed()
    }
  }, [width])
  return (
    <SiderStyled
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={() => handleCollapse()}
      width={250}
      className={"operation-sidebar"}
    >
      <div
        className={"operation-sidebar-trigger"}
        onClick={() => handleCollapse()}
      >
        <CollapseIcon
          className={collapsed ? "operation-sidebar-trigger-rotate" : ""}
        />
      </div>
      <Link href={"/"}>
        <a>
          <div className={"operation-siderbar-header-container"}>
            <div className={"operation-sidebar-header-logo"}>
              <EASYYLOGO />
            </div>
            {!collapsed && (
              <span className={"operation-sidebar-header-title"}>
                {t("Growth Rehabilitation Support System")}
              </span>
            )}
          </div>
        </a>
      </Link>
      <Menu
        mode={"inline"}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={[activeKey]}
      >
        {sideBarItems.map((sideMenu) => (
          <>
            <Menu.Item
              icon={sideMenu.icon}
              key={sideMenu.key}
              onClick={() => push(sideMenu.link)}
            >
              {t(sideMenu.label)}
            </Menu.Item>
            <Menu.Divider className={"operation-sidebar-menu-divider"} />
          </>
        ))}
      </Menu>
      <div className={"operation-dashboard-sidebar-logout"}>
        <Menu>
          <Menu.Item
            icon={
              <LogoutWrapper collapsed={collapsed}>
                <img src={"/assets/icons/logout.svg"} />
              </LogoutWrapper>
            }
            key={"logout"}
            onClick={logOut}
          >
            {t("Logout")}
          </Menu.Item>
        </Menu>
      </div>
    </SiderStyled>
  )
}

export { Sidebar }
