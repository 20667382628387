import { Layout } from "antd"
import styled from "styled-components"
const { Footer: AntFooter } = Layout

const FooterStyled = styled(AntFooter)`
  &.operation-dashboard-footer {
    height: 50px;
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 40px;
  }
  @media (max-width: 768px) {
    &.operation-dashboard-footer {
      flex-direction: column;
      padding: 10px;
    }
    .operation-dashboard-footer-copyright {
      margin-top: 10px;
    }
  }
`
const Footer = () => {
  return (
    <FooterStyled className={"operation-dashboard-footer"}>
      <span className={"operation-dashboard-footer-copyright"}>
        {"Copyright "}
        {"©"}
        {" Onelife.Inc"}
      </span>
    </FooterStyled>
  )
}

export { Footer }
