import { Layout as AntLayout } from "antd"
import styled from "styled-components"

export const Layout = styled(AntLayout)`
  &.dashboard {
    min-height: 100vh;
  }
  .sidebar-header-logo {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
  }
  .dashboard-content {
    padding: 24px;
  }
  .dashboard-mobile-sidebar-header {
    display: none;
  }
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 10px;
    }
    .dashboard-mobile-sidebar-header {
      background-color: #fdd95f;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .dashboard-mobile-siderbar-header-logo-title {
      display: flex;
      align-items: center;
    }
  }
`
