import { Layout } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface Props {
  title: string
}

const { Header: AntHeader } = Layout

const HeaderStyled = styled(AntHeader)`
  background-color: #ffffff;
`
const Header = (props: Props) => {
  const { title } = props
  const { t } = useTranslation()
  return <HeaderStyled>{t(`${title}`)}</HeaderStyled>
}

export { Header }
